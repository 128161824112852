//colors
$primary: #367351;
$primary-lighter: #3E8461;
$secondary: #d2ab66;
$error: #c2141c;
$grey: #888888;
$grey-lighter: #cccccc;
$info: #3498db;


* {
  box-sizing: border-box;
}

body {
  background-color: $primary;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  margin: 0 auto;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  width: 70%;
}

.hide {
  display: none !important;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.clearfix {
  clear: both;
}

.logo-container {

}

.logo {
  height: 250px;
  background: url('../img/logo.jpg') no-repeat center/contain;
  margin: auto;

  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/logo.jpg', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../img/logo.jpg',sizingMethod='scale')";
}

.card {
  background-color: white;
  padding: 15px;
  -webkit-box-shadow: 0 3px 4px rgba(50, 50, 50, .95);
  box-shadow: 0 3px 4px rgba(50, 50, 50, .95);
  margin-bottom: 15px;
}

.card:first-child {
  margin-top: 15px;
}

.card .title {
  text-transform: uppercase;
  color: $primary;
}

.form-group {
  position: relative;
  margin-bottom: 20px;
  padding-top: 15px;
}

.form-group:first-child {
  margin-top: 20px;
}

.form-group label {
  position: absolute;
  top: 0;
  left: 0;
  transition: top 0.7s ease, opacity 0.7s ease;

  font-size: 12px;
  font-weight: 600;
  color: $grey-lighter;
  text-transform: uppercase;
}

.form-group label.floating {
  opacity: 0;
}

.form-group > .form-control:valid + label {
  opacity: 1;
}

.form-group .form-control input:focus ~ label,
.form-group .form-control:focus + label {
  color: $secondary;
}

.form-control {
  display: block;
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #555;
  background: #fff none;
  border: none;
  border-bottom: 1px solid $grey-lighter;
  border-radius: 0;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control:focus,
.form-control:active {
  outline: none;
  border-color: $secondary;
}

.form-control.masked {
  font-family: monospace;
}

.form-control input {
  border: none;
  font-size: 16px;
  height: 100%;
}

.form-control input:focus {
  outline: none;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0;
  min-width: 15%;
  -webkit-box-shadow: 0 3px 4px rgba(50, 50, 50, .55);
  box-shadow: 0 3px 4px rgba(50, 50, 50, .55);
}

a.btn {
  text-decoration: none;
}

.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  min-width: 20%;
}

.btn-sm {
  padding: 3px 6px;
  min-width: 11%;
}

.btn-primary {
  color: white;
  background-color: $primary;
  border-color: $primary;
}

.btn-primary:hover {
  background-color: $primary-lighter;
  border-color: $primary-lighter;
}

.btn-secondary-outline {
  border: 1px solid $secondary;
  color: $secondary;
  background-color: white;
  -webkit-box-shadow: 0 2px 3px rgba(50, 50, 50, .2);
  box-shadow: 0 2px 3px rgba(50, 50, 50, .2);
}

.btn-secondary-outline:hover {
  background-color: $secondary;
  color: white;
}

.table {
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: 15px;
  text-align: center;
}

.table thead tr th,
.table tbody tr td {
  padding: 5px;
}

.table thead tr th {
  border-bottom: 1px solid #e67e22;
}

.card-header {
  text-transform: uppercase;
  color: $grey;
  text-align: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: #555 !important;
}

.issued-date, .expiry-date {
  font-weight: bold;
}

.expiry-date {
  color: $secondary;
}

.no-result {
  padding: 20px;
}

.scrollable {
  overflow-x: auto;
}

.verification-result {
  font-weight: bold;
  font-size: x-large;
  background-color: rgba(54, 115, 81, 0.05);
  margin-bottom: 10px;
}

.verified {
  color: $primary;
}

.unverified {
  color: $error;
}

.verified,
.unverified {
  float: right;
}

.verified i,
.unverified i {
  vertical-align: middle;
  top: -1px;
}

.sign-out-form {
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
}
/* alerts */
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  &.alert-info {
    color: $info;
    border-color: $info;
  }

  &.alert-danger {
    color: $error;
    border-color: $error;
  }

  &.alert-success {
      color: $primary;
      border-color: $primary;
  }
}


/* icons section */
@font-face {
  font-family: 'Glyphicons Halflings';
  src: url(../fonts/glyphicons-halflings-regular.eot);
  src: url(../fonts/glyphicons-halflings-regular.eot?#iefix) format('embedded-opentype'), url(../fonts/glyphicons-halflings-regular.woff) format('woff'), url(../fonts/glyphicons-halflings-regular.woff2) format('woff2'), url(../fonts/glyphicons-halflings-regular.ttf) format('truetype'), url(../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular) format('svg')
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-lg .glyphicon {
  top: 3px;
}

.glyphicon-ok-circle:before {
  content: "\e089";
}

.glyphicon-error-circle:before {
  content: "\e088";
}

.glyphicon-lock:before {
  content: "\e033";
}

.glyphicon-upload:before {
    content: "\e027";
}

.glyphicon-log-in:before {
    content: "\e161";
}

.glyphicon-log-out:before {
    content: "\e163";
}

.glyphicon-menu-left:before {
    content: "\e257";
}

/* end icons section */


/* mobile adjustments */
@media screen and (max-width: 1024px) {
  body {
    width: 100%;
  }

  .logo {
    height: 100px;
  }

  .card .title {
    font-size: 1.0rem;
  }
}
